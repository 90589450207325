<template>
  <v-container>
    <div>es darf aktulisiert werden ... vielleicht</div>
  </v-container>
</template>

<script>
export default {
  mounted() {
    console.log(this.$route.params.id);
  },
}
</script>
